import { ActionGroup, useIntersectionObserver } from '@flipgrid/flipkit';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import stickyBarStyles from '~/styles/components/FkWrappers/StickyBar.css';

import type { LinksFunction } from '@remix-run/node';
import type { ReactNode } from 'react';

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: stickyBarStyles }];
};

type Props = {
  children: ReactNode;
  className?: string;
  insideTabPanel?: boolean;
  [key: string]: unknown;
};

const StickyBar = ({ children, className, insideTabPanel, ...rest }: Props) => {
  const [stuck, setStuck] = useState(false);
  const stickyAnchorRef = useRef<HTMLDivElement>(null);

  // Watch div above StickyBar and return false if out of viewport. Needed for Intersection Observer to work.
  const isVisible = useIntersectionObserver(stickyAnchorRef);

  useEffect(() => {
    if (isVisible) setStuck(false);else
    setStuck(true);
  }, [isVisible]);

  const getStickybarClasses = classNames([
  'fk-stickyBar',
  { 'fk-stickyBar__insideTabsLayout': insideTabPanel },
  { 'fk-stickyBar__layout': !insideTabPanel },
  { 'fk-stickyBar__fixed': stuck },
  'stickyBar',
  className]
  );

  return (
    <>
      <div ref={stickyAnchorRef} className="stickyBar__observer" />
      <div className={getStickybarClasses} {...rest}>
        <ActionGroup stack="small" {...rest}>
          {children}
        </ActionGroup>
      </div>
    </>);

};

export default StickyBar;